import * as React from "react";
import { useState } from "react";

import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DrawerLink from "./DrawerLink";

const NavbarLink = {
  fontFamily: "marvin-round",
  fontSize: "15px",
  color: "#4D4D4E",
  textDecoration: "none",
  marginLeft: "1rem",
  marginRight: "1rem",
};

const DrawerBtn = {
  textDecoration: "none",
  backgroundColor: "transparent",
  border: "none",
  width: "100%",
};

export default function MainNav() {
  const [open, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#fff", boxShadow: 0 }}>
      <Container maxWidth="lg">
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              width: "auto",
              fontWeight: 700,
              display: { xs: "none", md: "block" },
              textAlign: "center",
            }}
          >
            <Link style={NavbarLink} to="/">
              Home
            </Link>
            <Link style={NavbarLink} to="/study-responsibilities">
              Study Responsibilities
            </Link>
            <Link style={NavbarLink} to="/health-exams-and-tests">
              Health Exams and Tests
            </Link>
            <Link style={NavbarLink} to="/study-clinic-visits">
              Study Clinic Visits
            </Link>
            <Link style={NavbarLink} to="/game-center">
              Game Center
            </Link>
            {/* <Link style={NavbarLink} to="/game-center">
              Game Center
            </Link> */}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{
              mr: 2,
              color: "#4d4d4e",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
              },
            }}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Box
              sx={{
                p: 2,
                height: 1,
                backgroundColor: "#fff",
              }}
            >
              {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
              <IconButton sx={{ mb: 2 }}>
                <CloseIcon onClick={toggleDrawer(false)} />
              </IconButton>

              <Divider sx={{ mb: 2 }} />
              <button onClick={toggleDrawer(false)} style={DrawerBtn}>
                <DrawerLink link="/" text="Home" />
              </button>
              <button onClick={toggleDrawer(false)} style={DrawerBtn}>
                <DrawerLink
                  link="/study-responsibilities"
                  text="Study Responsibilities"
                />
              </button>
              <button onClick={toggleDrawer(false)} style={DrawerBtn}>
                <DrawerLink
                  link="/health-exams-and-tests"
                  text="Health Exams and Tests"
                />
              </button>
              <button onClick={toggleDrawer(false)} style={DrawerBtn}>
                <DrawerLink
                  link="/study-clinic-visits"
                  text="Study Clinic Visits"
                />
              </button>
              <button onClick={toggleDrawer(false)} style={DrawerBtn}>
                <DrawerLink link="/game-center" text="Game Center" />
              </button>
            </Box>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
