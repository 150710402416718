import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import PageHero from "../components/heroes/PageHero";
import GameCenterModal from "../components/modals/GameCenterModal";
import GameCenterPlaceholder from "../images/game-center-placeholder.png";
import OperationOcean from "../images/operation-ocean-screenshot.png";
import AdventureSub from "../images/adventure-sub-screenshot.png";
import TreasuresOfTheSeas from "../images/treasures-of-the-seas-screenshot.png";
import Para from "../components/typography/Para";
import GameCenterModalLg from "../components/modals/GameCenterModalLg";
import GameCenterModalSm from "../components/modals/GameCenterModalSm";
import Game from "../components/games/Game";

const GameCenterHeading = {
  fontFamily: "brandon-grotesque",
  fontWeight: "700",
  fontSize: "1.8rem",
};

// const GameHeading = {
//   fontFamily: "brandon-grotesque",
//   fontWeight: "700",
//   fontSize: "1.5rem",
//   textAlign: "center",
//   margin: "0px",
// };

// const GameLink = {
//   fontFamily: "arial",
//   fontWeight: "700",
//   fontSize: "1rem",
//   textDecoration: "none",
//   color: "#0071CE",
// };

// const ResponsiveImg = {
//   width: "100%",
//   height: "auto",
// };

export default function GameCenter() {
  const sessionPageViewed = sessionStorage.getItem("gamePageVisited");

  useEffect(() => {
    if (sessionPageViewed === false) {
      sessionStorage.setItem("gamePageVisited", true);
    }
    if (sessionPageViewed === null || sessionPageViewed === undefined) {
      sessionStorage.setItem("gamePageVisited", false);
    }
  }, []);

  return (
    <Grid
      container
      sx={{ maxWidth: "1200px", m: "auto", backgroundColor: "#ffc423" }}
    >
      {sessionPageViewed ? (
        ""
      ) : (
        <Container sx={{ display: { xs: "block", md: "none" } }}>
          <GameCenterModalSm />
        </Container>
      )}
      <PageHero>Game Center</PageHero>
      {sessionPageViewed ? (
        ""
      ) : (
        <Container sx={{ display: { xs: "none", md: "block" } }}>
          <GameCenterModalLg />
        </Container>
      )}
      <Grid
        container
        sx={{
          maxWidth: "80%",
          m: "auto",
          paddingBottom: "3rem",
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <h2 style={GameCenterHeading}>Welcome to the Game Center page!</h2>
        </Grid>
        <Grid container spacing={4} sx={{ textAlign: "center" }}>
          <Game
            alt="Operation Ocean Screenshot"
            src={OperationOcean}
            title="Operation Ocean"
            description="A submarine dives deep underwater through a minefield with the goal of amassing jewels and treasures along the way."
            gamelink="/operation-ocean"
          />
          <Game
            alt="Adventure Sub Screenshot"
            src={AdventureSub}
            title="Adventure Sub"
            description="
            While trying to avoid underwater creatures, a submarine navigates the seabed, with the goal of amassing jewels along the way."
            gamelink="/adventure-sub"
          />
          <Game
            alt="Treasures of the Seas Screenshot"
            src={TreasuresOfTheSeas}
            title="Treasures of the Seas"
            description="Similar to “Candy Crush” this is a match-three puzzle video game."
            gamelink="/treasures-of-the-seas"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
