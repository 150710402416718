import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { Checkbox, FormControl, Grid } from "@mui/material";
import LightningBolt from "../../images/lightning-bolt.png";
import PeriscopeLogo from "../../images/periscope-logo-white-circle.png";
import Para from "../typography/Para";

import "../../layout.css";
import "./modal.css";

const GCModal = {
  bgcolor: "#f58020",
  border: "none",
  width: { xs: "80%", sm: "50%" },
  boxShadow: 24,
  p: 4,
};

const ResponsiveImg = {
  width: "75px",
  height: "91px",
};

const ResponsiveLogo = {
  width: "145px",
  height: "145px",
};

const ModalHeading = {
  fontFamily: "marvin-round",
  fontSize: "1.8rem",
  color: "#4D4D4E",
  marginBottom: "1rem",
};

const ModalListItem = {
  fontFamily: "brandon-grotesque",
  fontWeight: "700",
  fontSize: "1rem",
  color: "#000000",
  marginTop: "12px",
};

const GameBtn = styled(Button)`
  font-family: marvin-round;
  text-align: center;
  color: #4d4d4d;
  background-color: #fcd807;
  height: 3.5rem;
  border-radius: 15px;
  font-weight: 700;
  text-transform: none;
  &:hover {
    text-transform: none;
    color: #4d4d4d;
    background-color: #f7d308;
  }
`;

// const ModalParagraph = {
//   fontFamily: "arial",
//   fontSize: "1rem",
//   color: "#000",
//   textAlign: "left",
// };

export default function GameCenterModalLg() {
  const [open, setOpen] = React.useState(true);
  const error = React.useState(false);
  const handleClose = () => setOpen(false);

  const [isActive, setActive] = useState("false");

  const handleToggle = (event) => {
    event.preventDefault();
    setActive(!isActive);
  };

  const RandomListSet1 = [
    "Come to all study clinic visits (at the clinic and over the phone)",
    "Follow all instructions from the study doctor/staff",
    "Tell the study doctor/staff if you are feeling bad or worse than before",
  ];
  const RandomListSet2 = [
    "Tell the study doctor/staff if you have any changes to your medications",
    "Complete your eDiary as instructed",
    "Take the investigational drug as instructed and do not give it to anyone else",
  ];
  const RandomListSet3 = [
    "Do not take any other medications unless the study doctor/staff says it is okay",
    "Do not drink alcohol or take any illicit drugs",
    "Do not participate in any other research studies while you’re in this study",
  ];
  const RandomItem1 =
    RandomListSet1[Math.floor(Math.random() * RandomListSet1.length)];
  const RandomItem2 =
    RandomListSet2[Math.floor(Math.random() * RandomListSet2.length)];
  const RandomItem3 =
    RandomListSet3[Math.floor(Math.random() * RandomListSet3.length)];

  return (
    <div style={{ overflow: "hidden" }}>
      <div className={isActive ? "window-in-lg" : "window-out-lg"}>
        <Box sx={GCModal}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <img
                style={ResponsiveImg}
                alt="Lightning Bolt"
                src={LightningBolt}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h5" style={ModalHeading}>
                Before you start playing,
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "left", px: { xs: ".2rem", sm: "4rem" } }}
            >
              <Para>
                take a few moments to refresh your memory with these study
                reminders. Check off each one as you read it, then click the
                button below to start playing!
              </Para>
            </Grid>
            <form onSubmit={handleToggle}>
              <FormControl
                sx={{ width: "100%" }}
                component="checkbox"
                error={error}
                variant="standard"
              >
                <Grid
                  container
                  sx={{
                    paddingTop: "3rem",
                    paddingLeft: { xs: ".2rem", sm: "3rem" },
                    paddingRight: { xs: ".2rem", sm: "3rem" },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{ textAlign: { xs: "center" } }}
                  >
                    <input
                      class="checkbox"
                      id="item1"
                      type="checkbox"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <p style={ModalListItem}>{RandomItem1}</p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    paddingTop: "1rem",
                    paddingLeft: { xs: ".2rem", sm: "3rem" },
                    paddingRight: { xs: ".2rem", sm: "3rem" },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{ textAlign: { xs: "center" } }}
                  >
                    <input
                      class="checkbox"
                      id="item2"
                      type="checkbox"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <p style={ModalListItem}>{RandomItem2}</p>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    paddingTop: "1rem",
                    paddingLeft: { xs: ".2rem", sm: "3rem" },
                    paddingRight: { xs: ".2rem", sm: "3rem" },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{ textAlign: { xs: "center" } }}
                  >
                    <input
                      class="checkbox"
                      id="item3"
                      type="checkbox"
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    sx={{ textAlign: { xs: "center", sm: "left" } }}
                  >
                    <p style={ModalListItem}>{RandomItem3}</p>
                    <br />
                  </Grid>
                  {/* <Grid container sx={{ marginBottom: "2rem" }}>
                <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                  {RandomListSet1.map((List) => {
                    return (
                      <Grid item xs={12}>
                        <p style={ModalListItem}>{List}</p>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                  {RandomListSet2.map((List) => {
                    return (
                      <Grid item xs={12}>
                        <p style={ModalListItem}>{List}</p>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                  {RandomListSet3.map((List) => {
                    return (
                      <Grid item xs={12}>
                        <p style={ModalListItem}>{List}</p>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid> */}
                </Grid>
                <Grid container sx={{ maxWidth: "75%", mx: "auto" }}>
                  <Grid item xs={12}>
                    <GameBtn
                      variant="contained"
                      type="submit"
                      sx={{
                        width: { xs: "100%" },
                        fontSize: { xs: "1.5rem", sm: "2rem" },
                      }}
                    >
                      GAME ON!
                    </GameBtn>
                  </Grid>
                </Grid>
              </FormControl>
            </form>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  marginTop: { xs: "2rem", sm: "1rem" },
                }}
              >
                <img
                  style={ResponsiveLogo}
                  alt="Periscope Logo"
                  src={PeriscopeLogo}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className={isActive ? "page-mask-in" : "page-mask-out"}></div>
    </div>
  );
}
