import * as React from "react";

import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import PeriscopeLogo from "../../images/periscope-logo.png";

const FooterLink = {
  fontFamily: "Arial",
  fontSize: ".8rem",
  color: "#4D4D4E",
  marginRight: "2rem",
};

const ResponsiveImg = {
  width: "100%",
  height: "auto",
};

export default function Footer() {
  return (
    <Grid
      container
      sx={{
        maxWidth: "1200px",
        padding: "1rem",
        textAlign: "center",
        mx: "auto",
        backgroundColor: "#fff",
      }}
    >
      <Grid item xs={12} sm={3} sx={{ paddingTop: { xs: "1rem", sm: "0px" } }}>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: { xs: "6rem", sm: "10rem" },
            paddingRight: { xs: "6rem", sm: "0px" },
          }}
        >
          <img style={ResponsiveImg} alt="Periscope Logo" src={PeriscopeLogo} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={9}
        sx={{
          paddingTop: { xs: "1rem", sm: "2.5rem" },
          paddingLeft: { xs: "0px", sm: "3rem" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Link to="/" style={FooterLink}>
          Home
        </Link>
        <Link to="/study-responsibilities" style={FooterLink}>
          Study Responsibilities
        </Link>
        <Link to="/health-exams-and-tests" style={FooterLink}>
          Health Exams and Tests
        </Link>
        <Link to="/study-clinic-visits" style={FooterLink}>
          Study Clinic Visits
        </Link>
        {/* <Link to="/game-center" style={FooterLink}>
          Game Center
        </Link> */}
      </Grid>
    </Grid>
  );
}
