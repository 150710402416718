import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { Checkbox, Grid } from "@mui/material";
import LightningBolt from "../../images/lightning-bolt.png";
import PeriscopeLogo from "../../images/periscope-logo-white-circle.png";
import Para from "../typography/Para";

import "../../layout.css";

const GCModal = {
  position: "fixed",
  top: { xs: "80%", md: "75%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1200px",
  width: { xs: "65%", sm: "40%" },
  bgcolor: "#f58020",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const ResponsiveImg = {
  width: "75px",
  height: "91px",
  marginLeft: "40%",
};

const ResponsiveLogo = {
  width: "145px",
  height: "145px",
  marginTop: "1rem",
  marginLeft: "30%",
};

const ModalHeading = {
  fontFamily: "marvin-round",
  fontSize: "1.8rem",
  color: "#4D4D4E",
};

const ModalListItem = {
  fontFamily: "brandon-grotesque",
  fontWeight: "700",
  fontSize: "1.2rem",
  color: "#000000",
};

const GameBtn = styled(Button)`
  font-family: marvin-round;
  text-align: center;
  color: #4d4d4d;
  background-color: #fcd807;
  height: 3.5rem;
  border-radius: 15px;
  font-weight: 700;
  text-transform: none;
  &:hover {
    text-transform: none;
    color: #4d4d4d;
    background-color: #f7d308;
  }
`;

// const ModalParagraph = {
//   fontFamily: "arial",
//   fontSize: "1rem",
//   color: "#000",
//   textAlign: "left",
// };

export default function GameCenterModal() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  const RandomListSet1 = [
    "Come to all study clinic visits (at the clinic and over the phone)",
    "Follow all instructions from the study doctor/staff",
    "Tell the study doctor/staff if you are feeling bad or worse than before",
  ];
  const RandomListSet2 = [
    "Tell the study doctor/staff if you have any changes to your medications",
    "Complete your eDiary as instructed",
    "Take the investigational drug as instructed and do not give it to anyone else",
  ];
  const RandomListSet3 = [
    "Do not take any other medications unless the study doctor/staff says it is okay",
    "Do not drink alcohol or take any illicit drugs",
    "Do not participate in any other research studies while you’re in this study",
  ];
  const RandomItem1 =
    RandomListSet1[Math.floor(Math.random() * RandomListSet1.length)];
  const RandomItem2 =
    RandomListSet2[Math.floor(Math.random() * RandomListSet2.length)];
  const RandomItem3 =
    RandomListSet3[Math.floor(Math.random() * RandomListSet3.length)];

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={GCModal}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sx={{ display: "block", mx: "auto" }}>
            <img
              style={ResponsiveImg}
              alt="Lightning Bolt"
              src={LightningBolt}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <h2 style={ModalHeading}>Before you start playing,</h2>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "left", px: "3rem" }}>
            <Para>
              take a few moments to refresh your memory with these study
              reminders. Check off each one as you read it, then click the
              button below to start playing!
            </Para>
          </Grid>
          <Grid
            container
            sx={{
              paddingTop: "3rem",
              paddingLeft: "3rem",
              paddingRight: "3rem",
            }}
          >
            {/* {RandomListItems.map((a) => {
              return (
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <input
                      class="checkbox"
                      id="random"
                      type="checkbox"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <p style={ModalListItem}>{RandomItem}</p>
                  </Grid>
                </Grid>
              );
            })} */}
            <Grid item xs={12} sm={2}>
              <input class="checkbox" id="item1" type="checkbox" required />
            </Grid>
            <Grid item xs={12} sm={10}>
              <p style={ModalListItem}>{RandomItem1}</p>
            </Grid>
          </Grid>
          <Grid container sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
            <Grid item xs={12} sm={2}>
              <input class="checkbox" id="item2" type="checkbox" required />
            </Grid>
            <Grid item xs={12} sm={10}>
              <p style={ModalListItem}>{RandomItem2}</p>
            </Grid>
          </Grid>
          <Grid container sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
            <Grid item xs={12} sm={2}>
              <input class="checkbox" id="item3" type="checkbox" required />
            </Grid>
            <Grid item xs={12} sm={10}>
              <p style={ModalListItem}>{RandomItem3}</p>
              <br />
            </Grid>
          </Grid>
          <Grid container sx={{ maxWidth: "75%" }}>
            <Grid item xs={12}>
              <GameBtn
                variant="contained"
                onClick={handleClose}
                sx={{
                  width: { xs: "100%" },
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                GAME ON!
              </GameBtn>
            </Grid>
            <Grid item xs={12}>
              <img
                style={ResponsiveLogo}
                alt="Periscope Logo"
                src={PeriscopeLogo}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
