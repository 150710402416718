import React, { useEffect } from "react";
import MainNav from "./components/nav/MainNav";
import { Outlet } from "react-router-dom";
import Footer from "./components/nav/Footer";

function App() {
  useEffect(() => {
    document.title = "PERISCOPE Extension Research Study";
  }, []);

  return (
    <div>
      <MainNav />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
