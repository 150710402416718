import React from "react";

import { Grid } from "@mui/material";
import Para from "../typography/Para";

export default function Game(props) {
  const ResponsiveImg = {
    width: "100%",
    height: "auto",
  };

  const GameHeading = {
    fontFamily: "brandon-grotesque",
    fontWeight: "700",
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "0px",
  };

  const GameLink = {
    fontFamily: "arial",
    fontWeight: "700",
    fontSize: "1rem",
    textDecoration: "none",
    color: "#0071CE",
  };

  return (
    <Grid item xs={12} sm={4}>
      <img style={ResponsiveImg} alt={props.alt} src={props.src} />
      <h2 style={GameHeading}>{props.title}</h2>
      <Grid
        item
        xs={12}
        sx={{ textAlign: "left", minHeight: { xs: "1rem", sm: "6rem" } }}
      >
        <Para>{props.description}</Para>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "left" }}>
        <a style={GameLink} href={props.gamelink}>
          Play Game >
        </a>
      </Grid>
    </Grid>
  );
}
